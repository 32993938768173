import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
    <div className="background-container"></div>
    <div className="coming-soon">
      <h1>We will be back soon</h1>
      <p>We are working on something awesome and special. Stay tuned..!</p>
    </div>
  </div>
  );
}

export default App;
